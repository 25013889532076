import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import IndexComponent from './components/IndexComponent';
import FeedbackComponent from './components/FeedbackComponent';
import ResultComponent from './components/ResultComponent';
import LoginComponent from './components/LoginComponent';
import RegisterComponent from './components/RegisterComponent';
import ChatbotComponent from './components/ChatbotComponent'; // New import

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/login" />;
};

const App = () => {
  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<IndexComponent />} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/feedback" /> : <LoginComponent />} />
        <Route path="/register" element={isAuthenticated ? <Navigate to="/feedback" /> : <RegisterComponent />} />
        
        {/* Protected Routes */}
        <Route
          path="/feedback"
          element={
            <ProtectedRoute>
              <FeedbackComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/result"
          element={
            <ProtectedRoute>
              <ResultComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chatbot"
          element={
            <ProtectedRoute>
              <ChatbotComponent />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
