import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

const MicrophoneIcon = () => (
  <svg className="w-16 h-16 text-white" fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 1.5a3 3 0 00-3 3v7.5a3 3 0 106 0V4.5a3 3 0 00-3-3z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5a7.5 7.5 0 01-15 0M12 18v4.5M9.75 22.5h4.5" />
  </svg>
);

const ChatbotComponent = () => {
  const [messages, setMessages] = useState([]); // Stores chat messages
  const [businessType, setBusinessType] = useState(""); // Business type
  const [businessDescription, setBusinessDescription] = useState(""); // Business description
  const [overlayVisible, setOverlayVisible] = useState(true); // Overlay visibility
  const [userInput, setUserInput] = useState(""); // Text input
  const [mode, setMode] = useState("speech"); // Chat mode: "text" or "speech"
  const [isBotSpeaking, setIsBotSpeaking] = useState(false); // Bot speaking state
  const [isRecording, setIsRecording] = useState(false); // Voice recognition state
  const [isStartDisabled, setIsStartDisabled] = useState(false); // Start button state
  const [isInputDisabled, setIsInputDisabled] = useState(false); // Controls user input field
  const [sidebarOpen, setSidebarOpen] = useState(false); // Sidebar open/close state
  const [selectedVoice, setSelectedVoice] = useState(null); // Voice for text-to-speech

  const sessionIdRef = useRef(null); // Session ID
  const recognitionRef = useRef(null); // Speech recognition instance
  const silenceTimeoutId = useRef(null); // Silence timeout for speech recognition
  const isMountedRef = useRef(true); // Component mount state
  const userInputRef = useRef(null); // User input reference for focusing

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
      if (recognitionRef.current) recognitionRef.current.stop();
      window.speechSynthesis.cancel();
      clearTimeout(silenceTimeoutId.current);
    };
  }, []);

  // Load voices for speech synthesis
  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      const englishVoices = voices.filter((v) => v.lang.toLowerCase().includes("en"));
      const preferredVoice = englishVoices.find((v) =>
        v.name.toLowerCase().includes("google")
      );
      setSelectedVoice(preferredVoice || englishVoices[0]);
    };
  
    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
  
    return () => {
      window.speechSynthesis.onvoiceschanged = null;
    };
  }, []);
  
  // Initialize speech recognition
  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.error("Speech recognition not supported");
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = "en-US";

    recognition.onstart = () => setIsRecording(true);
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript.trim();
      handleUserInput(transcript);
    };
    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsRecording(false);
    };
    recognition.onend = () => setIsRecording(false);

    recognitionRef.current = recognition;
  }, []);

  // Start chatbot session
  const handleStart = async () => {
    if (!businessType || !businessDescription) {
      alert("Please provide both business type and description.");
      return;
    }

    setIsStartDisabled(true);
    try {
      const { data } = await axios.post("https://bizznizz.eu/api/start", {
        business_type: businessType,
        business_description: businessDescription,
      });

      if (data.session_id) {
        sessionIdRef.current = data.session_id;
        setOverlayVisible(false);
        addBotMessage(data.message);
      } else {
        alert("Failed to start session. Please try again.");
      }
    } catch (error) {
      console.error("Error starting session:", error);
      alert("Error starting session. Please try again.");
    } finally {
      setIsStartDisabled(false);
    }
  };

  // Handle user input (text or speech)
  const handleUserInput = async (input) => {
    if (!input) return;

    setMessages((prev) => [...prev, { role: "user", text: input }]);

    try {
      const { data } = await axios.post(
        "https://bizznizz.eu/api/message",
        { message: input },
        { headers: { "X-Session-Id": sessionIdRef.current } }
      );
      addBotMessage(data.message);
    } catch (error) {
      console.error("Error sending message:", error);
      addBotMessage("I'm sorry, there was an error processing your request.");
    }
  };

  // Add bot message and speak it
  const addBotMessage = (text) => {
    setMessages((prev) => [...prev, { role: "bot", text }]);

    if (mode === "speech" && text) {
      speakText(text, startRecognition);
    }
  };

  // Speak text using speech synthesis
  const speakText = (text, callback) => {
    if (!text || !window.speechSynthesis) return;
  
    const utterance = new SpeechSynthesisUtterance(text);
  
    // Ensure the same voice is always used
    if (selectedVoice) {
      utterance.voice = selectedVoice;
    } else {
      const voices = window.speechSynthesis.getVoices();
      const englishVoices = voices.filter((v) => v.lang.toLowerCase().includes("en"));
      utterance.voice =
        englishVoices.find((v) => v.name.toLowerCase().includes("google")) || englishVoices[0];
    }
  
    utterance.onstart = () => {
      setIsBotSpeaking(true);
      setIsInputDisabled(true); // Disable user input during bot speech
    };
    utterance.onend = () => {
      setIsBotSpeaking(false);
      setIsInputDisabled(false); // Re-enable user input after bot speech
      if (callback) callback();
    };
  
    window.speechSynthesis.speak(utterance);
  };

  // Start speech recognition
  const startRecognition = () => {
    if (recognitionRef.current && !isBotSpeaking && !isRecording) {
      recognitionRef.current.start();
    }
  };

  // Stop speech recognition
  const stopRecognition = () => {
    if (recognitionRef.current && isRecording) {
      recognitionRef.current.stop();
    }
  };

  // Handle send message for text mode
  const handleSendMessage = () => {
    if (userInput.trim()) {
      handleUserInput(userInput.trim());
      setUserInput("");
    }
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div className="h-screen font-montserrat flex flex-col overflow-hidden bg-gradient-to-b from-[#1C1C28] to-[#232333] text-white">
      {overlayVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="relative z-50 bg-[#2B2B38] p-8 rounded border border-gray-700 w-full max-w-xs text-center shadow-lg">
            <h2 className="text-white mb-5 text-lg font-semibold">Describe Your Business</h2>
            <label className="text-white block mb-1 font-semibold text-sm">Business Type:</label>
            <input
              type="text"
              value={businessType}
              onChange={(e) => setBusinessType(e.target.value)}
              className="mb-4 w-full p-2 border border-gray-600 rounded text-sm bg-gray-700 text-white focus:outline-none"
            />
            <label className="text-white block mb-1 font-semibold text-sm">Business Description:</label>
            <textarea
              rows="4"
              cols="50"
              value={businessDescription}
              onChange={(e) => setBusinessDescription(e.target.value)}
              className="w-full p-2 border border-gray-600 rounded mb-4 text-sm bg-gray-700 text-white focus:outline-none"
            ></textarea>
            <div className="mb-4 text-white text-sm text-left">
              <span className="font-semibold">Mode:</span>
              <div className="mt-2 space-y-2">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    value="text"
                    checked={mode === "text"}
                    onChange={() => setMode("text")}
                  />
                  <span>Text Mode</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    value="speech"
                    checked={mode === "speech"}
                    onChange={() => setMode("speech")}
                  />
                  <span>Speech Mode</span>
                </label>
              </div>
            </div>
            <button
              onClick={handleStart}
              disabled={isStartDisabled}
              className={`px-4 py-2 rounded text-white text-sm font-semibold transition ${
                isStartDisabled
                  ? "opacity-50 cursor-not-allowed bg-[#4088FF]"
                  : "bg-[#4088FF] hover:bg-blue-500"
              }`}
            >
              Start
            </button>
          </div>
        </div>
      )}

      {!overlayVisible && (
        <div className="bg-[#4088FF] text-white p-3 flex justify-between items-center shadow-md">
          <h1 className="m-0 text-xl font-bold">BizzNizz AI Builder</h1>
          {mode === "speech" && (
            <button
              onClick={toggleSidebar}
              className="text-white font-semibold text-sm border border-white rounded px-3 py-1 hover:bg-white hover:text-[#4088FF] transition"
            >
              {sidebarOpen ? "Close Chat" : "View Chat"}
            </button>
          )}
        </div>
      )}

      <div className="flex-1 flex relative overflow-hidden">
        {mode === "text" && !overlayVisible && (
          <div className="bg-[#2B2B38] border-r border-gray-700 p-5 overflow-auto md:w-72 w-full md:static hidden md:block">
            <h2 className="text-xl font-semibold mb-2 text-white">Business Info</h2>
            <div className="whitespace-pre-wrap text-gray-300 text-sm mb-4">
              {`Business Type: ${businessType}\nDescription: ${businessDescription}`}
            </div>
          </div>
        )}

        {mode === "speech" && !overlayVisible && (
          <div
            className={`fixed md:static inset-0 z-40 bg-[#2B2B38] p-5 border-r border-gray-700 transform transition-transform duration-300 ease-in-out overflow-auto md:w-72 w-full ${
              sidebarOpen ? "translate-x-0" : "-translate-x-full md:translate-x-0"
            }`}
          >
            <h2 className="text-xl font-semibold mb-2 text-white">Business Info</h2>
            <div className="whitespace-pre-wrap text-gray-300 text-sm mb-4">
              {`Business Type: ${businessType}\nDescription: ${businessDescription}`}
            </div>
            <div className="text-sm space-y-3 overflow-auto max-h-[calc(100vh-200px)] pb-10">
              {messages.map((m, i) => (
                <div
                  key={i}
                  className={`p-3 rounded-lg whitespace-pre-wrap break-words text-sm font-normal shadow ${
                    m.role === "bot" ? "bg-[#78AAFF] text-black" : "bg-[#92BBFF] text-black"
                  }`}
                >
                  {m.text}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className={`flex-1 flex flex-col relative ${mode === "text" ? "p-5" : "p-0"} overflow-hidden`}>
          {mode === "text" && !overlayVisible && (
            <div className="overflow-auto flex-1 pr-1">
              {messages.map((m, i) => (
                <div
                  key={i}
                  className={`mb-3 max-w-[90%] sm:max-w-[60%] p-3 rounded-lg whitespace-pre-wrap break-words clear-both text-sm font-normal shadow ${
                    m.role === "bot"
                      ? "bg-[#78AAFF] text-black float-left"
                      : "bg-[#92BBFF] text-black float-right"
                  }`}
                >
                  {m.text}
                </div>
              ))}
            </div>
          )}

          {mode === "speech" && !overlayVisible && (
            <div className="flex-1 flex items-center justify-center relative">
              <div className="relative flex items-center justify-center">
                <div
                  className={`absolute h-32 w-32 rounded-full border border-[#92BBFF] ${
                    isBotSpeaking ? "" : "animate-ping"
                  }`}
                />
                <div
                  className={`absolute h-20 w-20 rounded-full border border-[#92BBFF] ${
                    isBotSpeaking ? "" : "animate-ping delay-200"
                  }`}
                />
                <MicrophoneIcon />
              </div>
            </div>
          )}

          {mode === "text" && !overlayVisible && (
            <div className="bg-[#2B2B38] p-2 flex border-t border-gray-700">
              <input
                ref={userInputRef}
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Type your message and press Enter..."
                disabled={isInputDisabled}
                onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                className={`flex-1 p-2 border rounded mr-2 text-sm bg-gray-700 text-white focus:outline-none ${
                  isInputDisabled ? "cursor-not-allowed" : "border-gray-600"
                }`}
              />
              <button
                onClick={isRecording ? stopRecognition : startRecognition}
                className="bg-[#4088FF] text-white px-4 py-2 rounded text-sm font-semibold hover:bg-blue-500 mr-2"
              >
                {isRecording ? "🛑" : "🎤"}
              </button>
              <button
                onClick={handleSendMessage}
                disabled={isInputDisabled}
                className={`text-white px-4 py-2 rounded text-sm font-semibold transition ${
                  isInputDisabled
                    ? "opacity-50 cursor-not-allowed bg-[#4088FF]"
                    : "bg-[#4088FF] hover:bg-blue-500"
                }`}
              >
                Send
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatbotComponent;
